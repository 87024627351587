import React, { useState } from "react";

import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import CardHeader from "./CardHeader";
import { setForm, setProgress } from "../redux/features/ticketSlice";
import { useDispatch } from "react-redux";

const SponsorForm = ({ nextStep, prevStep }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Name is required")
      .max(25, "Event name must be at most 25 characters"),
    phoneNumber: Yup.string()
      .matches(/^(\+?234|0)?[789]\d{9}$/, "Invalid Nigerian Phone Number")
      .required("Phone Number is required"),
    type: Yup.string().required("Please select a type"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
  });

  const initialValues = {
    name: "",
    email: "",
    phoneNumber: "",
    type: "",
  };

  const BusinessTypeRadio = ({ value, label, checked, onChange }) => (
    <div className="flex items-center gap-x-3">
      <Field
        name="type"
        type="radio"
        value={value}
        checked={checked}
        onChange={onChange}
        className={`relative mr-1 mt-0.5 h-5 w-5 appearance-none rounded-full border-2 border-solid 
        before:pointer-events-none before:absolute before:h-4 before:w-4 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:border-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:border-primary checked:after:bg-primary checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:border-primary checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:border-neutral-600 dark:checked:border-primary dark:checked:after:border-primary dark:checked:after:bg-primary dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:border-primary dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]`}
      />
      {label}
    </div>
  );

  const handleSubmit = (values) => {
    dispatch(setForm(values));
    dispatch(setProgress(25));
    nextStep();
    // GO TO TICKET PAGE
  };
  return (
    <div className="body">
      <div className="containerOuter">
        <div className="">
          <div className="containerInner">
            <CardHeader />
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting, values, handleChange }) => (
                <Form>
                  <div className="w-full">
                    <div className="mb-5">
                      <Field
                        type="text"
                        id="name"
                        className={`rounded-lg  flex-1 appearance-none border 
                      w-full py-2 px-3 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-primary/80 focus:border-transparent`}
                        name="name"
                        placeholder="Enter your full name"
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                  </div>
                  <div className="w-full  mb-5">
                    <div className="">
                      <Field
                        type="email"
                        id="email"
                        className={`rounded-lg  flex-1 appearance-none border                 
                                     w-full py-2 px-3 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-primary/80 focus:border-transparent`}
                        name="email"
                        placeholder="Enter your email address"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                  </div>
                  <div className="w-full  mb-5">
                    <div className="">
                      <Field
                        type="text"
                        id="phoneNumber"
                        className={`rounded-lg  flex-1 appearance-none border
                    
                 
                     w-full py-2 px-3 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-primary/80 focus:border-transparent`}
                        name="phoneNumber"
                        placeholder="Enter your phoneNumber"
                      />
                      <ErrorMessage
                        name="phoneNumber"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                  </div>
                  <div className="w-full">
                    <div className="mb-4 md:mb-0 ">
                      <label
                        htmlFor="type"
                        className="text-[#333] mb-2 text-base font-medium"
                      >
                        Are you attending?
                        {/* <span className="text-red-500 required-dot">*</span> */}
                      </label>
                      <div className="flex gap-x-6 items-center">
                        <BusinessTypeRadio
                          value="true"
                          label="Yes"
                          checked={values.type === "true"}
                          onChange={handleChange}
                        />
                        <BusinessTypeRadio
                          value="false"
                          label="No"
                          checked={values.type === "false"}
                          onChange={handleChange}
                        />
                      </div>
                      <ErrorMessage
                        name="type"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                  </div>
                  <div class="flex flex-col md:flex-row items-center justify-between w-full gap-4 mt-8">
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="py-3 px-4 bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full   transition ease-in duration-200 text-center text-sm font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg md:order-2"
                    >
                      Continue
                    </button>
                    <button
                      type="button"
                      onClick={prevStep}
                      // onClick={() => navigate(-1)}
                      className="py-3 px-4 bg-white border border-primaryGrey text-grey100 w-full md:w-1/2 transition ease-in duration-200 text-center text-sm font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg md:order-1"
                    >
                      Go back
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SponsorForm;
