import { createSlice } from "@reduxjs/toolkit";

export const ticketSlice = createSlice({
  name: "tickets",
  initialState: {
    age0to2: {
      quantity: 0,
      ticketPrice: 100,
      total: 0,
    },
    age8to12: {
      quantity: 0,
      ticketPrice: 4000,
      total: 0,
    },
    age13to15: {
      quantity: 0,
      ticketPrice: 5000,
      total: 0,
    },
    adult: {
      quantity: 0,
      ticketPrice: 4000,
      total: 0,
    },
    child: {
      quantity: 0,
      ticketPrice: 5000,
      total: 0,
    },
    form: {},
    location: "",
    progress: 0,
    total: 0,
  },
  reducers: {
    incrementQuantity: (state, action) => {
      const { category } = action.payload;
      if (state[category]) {
        state[category].quantity += 1;
        state[category].total =
          state[category].quantity * state[category].ticketPrice;
        state.total += state[category].ticketPrice;
      }
    },
    decrementQuantity: (state, action) => {
      const { category } = action.payload;
      const ticketCategory = state[category];
      if (ticketCategory && ticketCategory.quantity > 0) {
        ticketCategory.quantity -= 1;
        state.total -= ticketCategory.ticketPrice;
        state[category].total =
          state[category].quantity * state[category].ticketPrice;
      }
    },
    setLocation: (state, action) => {
      state.location = action.payload;
    },
    setForm: (state, action) => {
      state.form = action.payload;
    },
    setProgress: (state, action) => {
      state.progress += action.payload;
    },
  },
});

export const {
  incrementQuantity,
  decrementQuantity,
  setLocation,
  setForm,
  setProgress,
} = ticketSlice.actions;

export default ticketSlice.reducer;
