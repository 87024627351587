import React from "react";
import Logo from "../assets/svgs/Logo-2.svg";
import { BsFiletypePdf } from "react-icons/bs";
import { useNavigate } from "react-router";

const Download = () => {
  const navigate = useNavigate();
  return (
    <div className="containerOuter">
      <div class="containerInner shadow-2xl">
        <div class="w-full h-full text-center">
          <h2 className="text-[#DE4699] font-black text-2xl">
            See you there! 🥳
          </h2>
          <div class="flex flex-col justify-between">
            <div className="flex justify-center">
              <img src={Logo} alt="checked-icon" className="w-60 h-60" />
            </div>
            <p className=" text-primary text-base font-semibold dark:text-white">
              Download your tickets now! 
            </p>
            <p class="text-[#777980] font-normal text-sm">
              We've sent a link to your email at{" "}
              <span className="text-[#161721] font-medium">
                *gh*@gmail.com{" "}
              </span>{" "}
              and an sms to your phone number at{" "}
              <span className="text-[#161721] font-medium"> +234**736**</span>{" "}
              for order
              <span className="text-primary font-medium">#1357368</span>. Enjoy
              the show!
            </p>
            <div className="flex justify-center mt-10">
              <button
                type="button"
                onClick={() => navigate("/downloads")}
                className="flex items-center  gap-x-10 py-3 px-4 bg-primary hover:bg-primary focus:ring-primary focus:ring-offset-indigo-200 text-white w-fit   transition ease-in duration-200 text-center text-sm font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg md:order-2"
              >
                Download ticket <BsFiletypePdf />
              </button>
            </div>
            <p className="text-center my-4 font-semibold text-sm">
              Downloaded 2 times
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Download;
