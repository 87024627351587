import React from "react";
import Logo from "../assets/svgs/Logo-2.svg";
import StepProgress from "./StepProgress";

const CardHeader = () => {
  return (
    <div>
      <div className="hidden md:flex justify-center md:mb-5">
        <img className="w-20 h-auto" src={Logo} alt="quiz-logo" />
      </div>
      <h3 className="heading">SteamFestAfrica 2024</h3>
      <div className="mb-7">
        <div className="flex justify-between items-center">
          <div className="">
            <h1 className="text-base font-semibold text-black100">
              Individual Tickets
            </h1>

            <p className="text-sm font-medium text-grey100">Sponsor details</p>
          </div>
          <StepProgress />
        </div>
      </div>
    </div>
  );
};

export default CardHeader;
