import React, { useState } from "react";
import Venue from "./Venue";
import SponsorForm from "./SponsorForm";
import SelectTicket from "./SelectTicket";

const MultiStepForm = () => {
  const [step, setStep] = useState(1); // Manage current step
  const [formData, setFormData] = useState({}); // Store form data across steps

  const nextStep = (data) => {
    setFormData({ ...formData, ...data }); // Merge new data with existing form data
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  switch (step) {
    case 1:
      return <Venue nextStep={nextStep} />;
    case 2:
      return <SponsorForm nextStep={nextStep} prevStep={prevStep} />;
    case 3:
      return <SelectTicket formData={formData} prevStep={prevStep} />;
    default:
      return null;
  }
};

export default MultiStepForm;
