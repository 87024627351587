import { combineReducers, configureStore } from "@reduxjs/toolkit";
// import storage from "redux-persist/lib/storage";

// import {
//   persistStore,
//   persistReducer,
//   FLUSH,
//   REHYDRATE,
//   PAUSE,
//   PERSIST,
//   PURGE,
//   REGISTER,
// } from "redux-persist";

import ticketReducer from "./features/ticketSlice";

const rootReducer = combineReducers({
  tickets: ticketReducer,
});

// const persistConfig = {
//   key: "root",
//   storage,
//   whitelist: [
//     "campaigns",
//     "summaryData",
//     "corporateAffairsData",
//     "flaggedIssuesData",
//     "reportsData",
//     "evaluatorData",
//     "branchesData",
//     "corporateData",
//   ], // Names of reducers to be persisted
// };

// const persistedReducer = persistReducer(persistConfig, rootReducer);

// const middleware = (getDefaultMiddleware) =>
//   getDefaultMiddleware({
//     serializableCheck: {
//       ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
//     },
//   });

let store = configureStore({
  reducer: rootReducer,
  // reducer: persistedReducer,
  // middleware,
  devTools: process.env.NODE_ENV !== "production", // Enable devtools only in development
});

// const persistor = persistStore(store);
export { store };
// export { store, persistor };
