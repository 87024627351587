import React, { useState } from "react";
import CardHeader from "./CardHeader";
import { TiInfoOutline } from "react-icons/ti";
import { HiOutlinePlus, HiOutlineMinusSmall } from "react-icons/hi2";
import { useSelector, useDispatch } from "react-redux";
import {
  incrementQuantity,
  decrementQuantity,
} from "../redux/features/ticketSlice";
import { useNavigate } from "react-router";
import { api } from "./../api/auth";
import { toast } from "react-toastify";

const SelectTicket = ({ nextStep, prevStep }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //quantity display
  const quantity = useSelector((state) => state.tickets.age0to2.quantity);
  const age8Quantity = useSelector((state) => state.tickets.age8to12.quantity);
  const age12Quantity = useSelector(
    (state) => state.tickets.age13to15.quantity
  );
  const adultQuantity = useSelector((state) => state.tickets.adult.quantity);
  const childQuantity = useSelector((state) => state.tickets.child.quantity);

  const sponsorDetails = useSelector((state) => state.tickets.form);

  const location = useSelector((state) => state.tickets.location);
  const totalAmount = useSelector((state) => state.tickets.total);
  const totalQuantity =
    quantity + age8Quantity + age12Quantity + adultQuantity + childQuantity;

  // total amount for each group
  const age0to2Total = useSelector((state) => state.tickets.age0to2.total);
  const age8to12Total = useSelector((state) => state.tickets.age8to12.total);
  const age13to15Total = useSelector((state) => state.tickets.age13to15.total);
  const adultTotal = useSelector((state) => state.tickets.adult.total);
  const childTotal = useSelector((state) => state.tickets.child.total);

  // for checkboxes
  const [checkedBoxes, setCheckedBoxes] = useState({
    quiz: false,
    marketing: false,
    terms: false,
  });
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCheckedBoxes({ ...checkedBoxes, [name]: checked });
  };
  const isContinueButtonDisabled =
    !checkedBoxes.quiz || !checkedBoxes.marketing || !checkedBoxes.terms;
  // end

  const handleIncrement = (category) => {
    dispatch(incrementQuantity({ category }));
  };

  const handleDecrement = (category) => {
    dispatch(decrementQuantity({ category }));
  };

  const handleSubmit = async () => {
    const payload = {
      amount: totalAmount,
      location: location,
      sponsor_name: sponsorDetails.name,
      sponsor_email: sponsorDetails.email,
      sponsor_phone_number: sponsorDetails.phoneNumber,
      attending: sponsorDetails.type,
      accept_all_consent: true,
      tickets: [
        {
          type: "creche",
          age_range: "0-2",
          quantity: quantity,
        },
        {
          type: "participant",
          age_range: "8-12",
          quantity: age8Quantity,
        },
        {
          type: "participant",
          age_range: "13-15",
          quantity: age12Quantity,
        },
        {
          type: "participant",
          age_range: "adult",
          quantity: adultQuantity,
        },
        {
          type: "participant",
          age_range: "child",
          quantity: childQuantity,
        },
      ],
    };
    try {
      const response = await api.register(payload);
      toast.success(response.message);
      navigate("/download");
      // SUBMIT AND GOT TO DOWNLOAD PAGE
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
    }
  };

  return (
    <div className="body">
      <div className="containerOuter">
        <div className="md:mt-20 mb-10">
          <div className="w-full min-h-[12rem] md:w-[500px] bg-white md:rounded-[20px] flex flex-col justify-center p-4 md:p-8 relative">
            <CardHeader />
            <div className="flex space-x-4 items-center bg-primary p-2 text-white mb-4">
              <TiInfoOutline />
              <p className="font-semibold text-xs">
                Please note that no refreshments will be provided at the event
              </p>
            </div>
            <div>
              <div className="flex justify-between mb-4">
                <p className="text-sm font-bold text-[#777980]">
                  Tickets selected
                </p>
                <div className="bg-primary text-white font-semibold text-base px-2 rounded">
                  {totalQuantity}
                </div>
              </div>

              <div>
                <hr />
                <div className="flex justify-between items-center my-2">
                  <div className="flex flex-col">
                    <p className="text-base font-medium">
                      Age 0-2{" "}
                      <span className="text-grey100 text-sm">(no crèche)</span>
                    </p>
                    <p className="text-black500 font-medium text-base">Free</p>
                  </div>
                  <div>
                    <div className="inline-flex items-center mt-2">
                      <button
                        onClick={() => handleDecrement("age0to2")}
                        className="bg-white rounded-l-md border text-gray-600 hover:bg-gray-100 active:bg-gray-200 disabled:opacity-50 inline-flex items-center p-2 border-r border-primary"
                      >
                        <HiOutlineMinusSmall />
                      </button>
                      <div class="bg-gray-100 border-t border-b border-gray-100 text-gray-600 hover:bg-gray-100 inline-flex items-center px-4 py-1 select-none">
                        {quantity}
                      </div>
                      <button
                        onClick={() => handleIncrement("age0to2")}
                        className="bg-white rounded-r-md border text-gray-600
                        hover:bg-gray-100 active:bg-gray-200 disabled:opacity-50
                        inline-flex items-center p-2 border-r border-primary"
                      >
                        <HiOutlinePlus />
                      </button>
                    </div>
                    <p className="text-end mt-2 font-semibold text-lg text-black200">
                      {age0to2Total.toLocaleString()}
                    </p>
                  </div>
                </div>
                <hr />
                <div className="flex justify-between items-center my-2">
                  <div className="flex flex-col">
                    <p className="text-base font-medium">
                      Age 8 - 12
                      <span className="text-grey100 text-sm">
                        {" "}
                        (Participant)
                      </span>
                    </p>
                    <div className="flex">
                      <p className="line-through  text-red-500">
                        <span className="text-grey100">5,000</span>
                      </p>
                      <p className="ml-2 text-black500 font-medium text-base">
                        N4,000
                      </p>
                    </div>
                  </div>
                  <div>
                    <div className="inline-flex items-center mt-2">
                      <button
                        onClick={() => handleDecrement("age8to12")}
                        className="bg-white rounded-l border text-gray-600 hover:bg-gray-100 active:bg-gray-200 disabled:opacity-50 inline-flex items-center p-2 border-r border-primary"
                      >
                        <HiOutlineMinusSmall />
                      </button>
                      <div class="bg-gray-100 border-t border-b border-gray-100 text-gray-600 hover:bg-gray-100 inline-flex items-center px-4 py-1 select-none">
                        {age8Quantity}
                      </div>
                      <button
                        onClick={() => handleIncrement("age8to12")}
                        className="bg-white rounded-r border text-gray-600 hover:bg-gray-100 active:bg-gray-200 disabled:opacity-50 inline-flex items-center p-2 border-r border-primary"
                      >
                        <HiOutlinePlus />
                      </button>
                    </div>
                    <p className="text-end mt-2 font-semibold text-lg text-black200">
                      {age8to12Total.toLocaleString()}
                    </p>
                  </div>
                </div>
                <hr />
                <div className="flex justify-between items-center my-2">
                  <div className="flex flex-col">
                    <p className="text-base font-medium">
                      Age 13 - 15
                      <span className="text-grey100 text-sm">
                        {" "}
                        (Participant)
                      </span>
                    </p>
                    <p className="text-black500 font-medium text-base">
                      N5,000
                    </p>
                  </div>
                  <div>
                    <div className="inline-flex items-center mt-2">
                      <button
                        onClick={() => handleDecrement("age13to15")}
                        className="bg-white rounded-l border text-gray-600 hover:bg-gray-100 active:bg-gray-200 disabled:opacity-50 inline-flex items-center p-2 border-r border-primary"
                      >
                        <HiOutlineMinusSmall />
                      </button>
                      <div class="bg-gray-100 border-t border-b border-gray-100 text-gray-600 hover:bg-gray-100 inline-flex items-center px-4 py-1 select-none">
                        {age12Quantity}
                      </div>
                      <button
                        onClick={() => handleIncrement("age13to15")}
                        className="bg-white rounded-r border text-gray-600 hover:bg-gray-100 active:bg-gray-200 disabled:opacity-50 inline-flex items-center p-2 border-r border-primary"
                      >
                        <HiOutlinePlus />
                      </button>
                    </div>
                    <p className="text-end mt-2 font-semibold text-lg text-black200">
                      {age13to15Total.toLocaleString()}
                    </p>
                  </div>
                </div>
                <hr />
                <div className="flex justify-between items-center my-2">
                  <div className="flex flex-col">
                    <p className="text-base font-medium">
                      Adult
                      <span className="text-grey100 text-sm">
                        {" "}
                        (Participant)
                      </span>
                    </p>
                    <div className="flex">
                      <p className="line-through text-red-500">
                        <span className="text-grey100">5,000</span>
                      </p>
                      <p className="ml-2 text-black500 font-medium text-base">
                        N4,000
                      </p>
                    </div>
                  </div>
                  <div>
                    <div className="inline-flex items-center mt-2">
                      <button
                        onClick={() => handleDecrement("adult")}
                        className="bg-white rounded-l border text-gray-600 hover:bg-gray-100 active:bg-gray-200 disabled:opacity-50 inline-flex items-center p-2 border-r border-primary"
                      >
                        <HiOutlineMinusSmall />
                      </button>
                      <div className="bg-gray-100 border-t border-b border-gray-100 text-gray-600 hover:bg-gray-100 inline-flex items-center px-4 py-1 select-none">
                        {adultQuantity}
                      </div>
                      <button
                        onClick={() => handleIncrement("adult")}
                        className="bg-white rounded-r border text-gray-600 hover:bg-gray-100 active:bg-gray-200 disabled:opacity-50 inline-flex items-center p-2 border-r border-primary"
                      >
                        <HiOutlinePlus />
                      </button>
                    </div>
                    <p className="text-end mt-2 font-semibold text-lg text-black200">
                      {adultTotal.toLocaleString()}
                    </p>
                  </div>
                </div>
                <hr />
                <div className="flex justify-between items-center my-2">
                  <div className="flex flex-col">
                    <p className="text-base font-medium">
                      Child
                      <span className="text-grey100 text-sm">
                        {" "}
                        (Participant)
                      </span>
                    </p>
                    <p className="text-black500 font-medium text-base">
                      N5,000
                    </p>
                  </div>
                  <div>
                    <div className="inline-flex items-center mt-2">
                      <button
                        onClick={() => handleDecrement("child")}
                        className="bg-white rounded-l border text-gray-600 hover:bg-gray-100 active:bg-gray-200 disabled:opacity-50 inline-flex items-center p-2 border-r border-primary"
                      >
                        <HiOutlineMinusSmall />
                      </button>
                      <div className="bg-gray-100 border-t border-b border-gray-100 text-gray-600 hover:bg-gray-100 inline-flex items-center px-4 py-1 select-none">
                        {childQuantity}
                      </div>
                      <button
                        onClick={() => handleIncrement("child")}
                        className="bg-white rounded-r border text-gray-600 hover:bg-gray-100 active:bg-gray-200 disabled:opacity-50 inline-flex items-center p-2 border-r border-primary"
                      >
                        <HiOutlinePlus />
                      </button>
                    </div>
                    <p className="text-end mt-2 font-semibold text-lg text-black200">
                      {childTotal.toLocaleString()}
                    </p>
                  </div>
                </div>
                <hr />
                <div className="flex justify-between items-center my-4">
                  <p>Total</p>
                  <p>₦ {totalAmount.toLocaleString()}</p>
                </div>
              </div>
            </div>

            <div>
              <div>
                <label className="flex items-center mb-3 space-x-3">
                  <input
                    type="checkbox"
                    name="quiz"
                    checked={checkedBoxes.quiz}
                    onChange={handleCheckboxChange}
                    className="bg-primary h-4 w-4 border border-gray-300 rounded-md checked:bg-primary checked:border-transparent focus:outline-none"
                  />
                  <span className=" text-sm font-normal text-gray-700 dark:text-white">
                    I consent for my children to take part in the quiz.
                  </span>
                </label>
                <label className="flex items-start mb-3 space-x-3">
                  <input
                    type="checkbox"
                    name="marketing"
                    checked={checkedBoxes.marketing}
                    onChange={handleCheckboxChange}
                    className="bg-primary h-6 w-6 border border-gray-300 rounded-md checked:bg-primary checked:border-transparent focus:outline-none"
                  />
                  <span className="text-sm font-normal text-gray-700 dark:text-white">
                    I consent for the pictures/videos of those who attend to be
                    used for any marketing, publication, or promotional content
                    for which it might be suitable.
                  </span>
                </label>
                <label className="flex items-center mb-3 space-x-3">
                  <input
                    type="checkbox"
                    name="terms"
                    checked={checkedBoxes.terms}
                    onChange={handleCheckboxChange}
                    className="bg-primary h-4 w-4 border border-gray-300 rounded-md checked:bg-primary checked:border-transparent focus:outline-none"
                  />
                  <span className="text-sm font-normal text-gray-700 dark:text-white">
                    By selecting Place Order, I agree to{" "}
                    <span className="text-primary">
                      SteamFunFest Terms and Conditions
                    </span>
                  </span>
                </label>
              </div>
              <div className="flex flex-col md:flex-row items-center justify-between w-full gap-4 mt-8">
                <button
                  type="submit"
                  onClick={handleSubmit}
                  className={`py-3 px-4 bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-sm font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg md:order-2 ${
                    isContinueButtonDisabled && "opacity-50 cursor-not-allowed"
                  }`}
                  disabled={isContinueButtonDisabled}
                >
                  Pay ₦ {totalAmount.toLocaleString()}
                </button>
                <button
                  type="button"
                  onClick={prevStep}
                  //   onClick={() => navigate(-1)}
                  className="py-3 px-4 bg-white border border-primaryGrey text-grey100 w-full md:w-1/2 transition ease-in duration-200 text-center text-sm font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg md:order-1"
                >
                  Go back
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectTicket;
