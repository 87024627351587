import React, { useState } from "react";
import { CiLocationOn } from "react-icons/ci";
import CardHeader from "./CardHeader";
import { useNavigate } from "react-router";
import { Formik, Form, Field, ErrorMessage, useFormik } from "formik";
import * as Yup from "yup";
import { setLocation, setProgress } from "../redux/features/ticketSlice";
import { useDispatch } from "react-redux";

const Venue = ({ nextStep }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [venue, setVenue] = useState("");

  const validationSchema = Yup.object().shape({
    venue: Yup.string().required("Please select a venue"),
  });

  const initialValues = {
    venue: "",
  };

  const handleSubmit = (values) => {
    nextStep(values);
    dispatch(setLocation(venue));
    dispatch(setProgress(25));
    console.log(values, "kini values");
    // GO TO sponsor form PAGE
  };

  return (
    <div className="body">
      <div className="containerOuter">
        <div className="">
          <div className="containerInner">
            <CardHeader />
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting, values, handleChange }) => (
                <Form>
                  <div className="mb-4 ">
                    <div className="grid grid-cols-2 gap-4">
                      <div
                        className={`w-full cursor-pointer  rounded-lg border border-gray-100 bg-white p-4 text-sm font-medium shadow-sm hover:border-primary has-[:checked]:border-primary has-[:checked]:ring-1 has-[:checked]:ring-primary ${
                          venue === "Abuja" ? "border-primary" : ""
                        }`}
                        onClick={() => {
                          setVenue("Abuja");
                          handleChange("venue")("Abuja");
                        }}
                      >
                        <Field
                          type="radio"
                          name="venue"
                          value="Abuja"
                          className={`flex items-start relative mr-1 mt-0.5 h-5 w-5 appearance-none rounded-full border-2 border-solid border-neutral-300
                    before:pointer-events-none before:absolute before:h-4 before:w-4 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:border-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:border-primary checked:after:bg-primary checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:border-primary checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:border-neutral-600 dark:checked:border-primary dark:checked:after:border-primary dark:checked:after:bg-primary dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:border-primary dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]`}
                        />
                        <div className="flex justify-center">
                          <CiLocationOn className="" />
                        </div>
                        <div className="flex flex-col text-center">
                          <h4 className="font-semibold text-lg text-black500 mb-4">
                            Abuja
                          </h4>
                          <p className="text-xs font-medium">Select venue</p>
                        </div>
                      </div>
                      <div
                        className={`w-full cursor-pointer  rounded-lg border border-gray-100 bg-white p-4 text-sm font-medium shadow-sm hover:border-primary has-[:checked]:border-primary has-[:checked]:ring-1 has-[:checked]:ring-primary ${
                          venue === "Lagos" ? "border-primary" : ""
                        }`}
                        onClick={() => {
                          setVenue("Lagos");
                          handleChange("venue")("Lagos");
                        }}
                      >
                        <Field
                          type="radio"
                          name="venue"
                          value="Lagos"
                          className={`flex items-start relative mr-1 mt-0.5 h-5 w-5 appearance-none rounded-full border-2 border-solid border-neutral-300
                    before:pointer-events-none before:absolute before:h-4 before:w-4 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:border-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:border-primary checked:after:bg-primary checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:border-primary checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:border-neutral-600 dark:checked:border-primary dark:checked:after:border-primary dark:checked:after:bg-primary dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:border-primary dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]`}
                        />
                        <div className="flex justify-center">
                          <CiLocationOn className="" />
                        </div>
                        <div className="flex flex-col text-center">
                          <h4 className="font-semibold text-lg text-black500 mb-4">
                            Lagos
                          </h4>
                          <p className="text-xs font-medium">Select venue</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col md:flex-row items-center justify-between w-full gap-4 mt-8">
                    <button
                      type="submit"
                      className="py-3 px-4 bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full   transition ease-in duration-200 text-center text-sm font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg md:order-2"
                    >
                      Continue
                    </button>
                    <button
                      type="button"
                      onClick={() => navigate(-1)}
                      className="py-3 px-4 bg-white border border-primaryGrey text-grey100 w-full md:w-1/2 transition ease-in duration-200 text-center text-sm font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg md:order-1"
                    >
                      Go back
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Venue;
