import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Navbar from "./components/Navbar";
import Venue from "./components/Venue";
import SponsorForm from "./components/SponsorForm";
import SuccessPage from "./components/SuccessPage";
import Download from "./components/Download";
import DownloadFilter from "./components/DownloadFilter";
import SelectTicket from "./components/SelectTicket";
import MultiStepForm from "./components/MultiStepForm";

function Hello() {
  return <div>Hello</div>;
}

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" element={<MultiStepForm />} />
          {/* <Route path="/" element={<Venue />} /> */}
          <Route path="/hello" element={<Hello />} />
          <Route path="/form" element={<SponsorForm />} />
          <Route path="/success" element={<SuccessPage />} />
          <Route path="/download" element={<Download />} />
          <Route path="/downloads" element={<DownloadFilter />} />
          <Route path="/ticket" element={<SelectTicket />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
