import createApiClient from "./AxiosInstance";

// Create an Axios instance
const client = createApiClient();

export const api = {
  //LOGIN
  register: (payload) =>
    client
      .post("/user/steam-register", { ...payload })
      .then(({ data }) => data),

  getTickets: (userId) =>
    client.get(`/user/steam-ticket/${userId}`).then(({ data }) => data),

  getFreeSpace: (location) =>
    client.get(`/user/free-space/${location}`).then(({ data }) => data),
};
