import React from "react";
import CheckedIcon from "../assets/svgs/Checked_Icon.svg";
import { BsFiletypePdf } from "react-icons/bs";
import { useNavigate } from "react-router";

const SuccessPage = () => {
  const navigate = useNavigate();
  return (
    <div className="containerOuter">
      <div class="containerInner shadow-2xl">
        <div class="w-full h-full text-center">
          <div class="flex flex-col justify-between">
            <div className="flex justify-center">
              <img src={CheckedIcon} alt="checked-icon" className="w-40 h-60" />
            </div>

            <p className=" flex gap-2 justify-center items-center my-2 text-primary text-lg font-semibold text-gray-900 dark:text-white">
              <BsFiletypePdf /> File size: 200mb
            </p>
            <p className="text-[#777980] font-black text-2xl">
              Download complete
            </p>
          </div>
          <div className="flex flex-col md:flex-row items-center justify-between w-full gap-4 mt-8">
            <button
              type="button"
              className="py-3 px-4 bg-primary hover:bg-primary focus:ring-primary focus:ring-offset-indigo-200 text-white w-full   transition ease-in duration-200 text-center text-sm font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg md:order-2"
            >
              Buy again
            </button>
            <button
              type="button"
              onClick={() => navigate(-1)}
              className="py-3 px-4 bg-white border border-primaryGrey text-grey100 w-full md:w-1/2 transition ease-in duration-200 text-center text-sm font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg md:order-1"
            >
              Go back
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessPage;
