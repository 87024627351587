import React, { useState } from "react";
import Logo from "../assets/svgs/Logo-2.svg";
import { BsFiletypePdf } from "react-icons/bs";
import SingleSelectDropdown from "./SingleSelectDropdown";
import { useNavigate } from "react-router";

const DownloadTypes = [
  { label: "All", value: "corporate" },
  { label: "Age 8 to 12", value: "individual" },
  { label: "Age 12 to 15", value: "individuals" },
];

const DownloadFilter = () => {
  const navigate = useNavigate();
  const [selectedDownloadType, setSelectedDownloadType] = useState(null);
  const handleSelectedDownloadType = (option) => {
    setSelectedDownloadType(option.value);
  };
  return (
    <div className="containerOuter">
      <div class="containerInner shadow-2xl">
        <div class="w-full h-full text-center">
          <h2 className="text-[#DE4699] font-black text-2xl">
            See you there! 🥳
          </h2>
          <div class="flex flex-col justify-between">
            <div className="flex justify-center">
              <img src={Logo} alt="checked-icon" className="w-60 h-60" />
            </div>
            <p className=" text-primary text-base font-semibold dark:text-white">
              Download your tickets now! 
            </p>
            <p class="text-[#777980] font-normal text-sm">
              We've sent a link to your email at{" "}
              <span className="text-[#161721] font-medium">
                *gh*@gmail.com{" "}
              </span>{" "}
              and an sms to your phone number at{" "}
              <span className="text-[#161721] font-medium"> +234**736**</span>{" "}
              for order
              <span className="text-primary font-medium">#1357368</span>. Enjoy
              the show!
            </p>
            <div className="flex justify-between items-center border border-primary rounded-lg p-2 mt-5">
              <div className="flex flex-col items-start">
                <span className="text-primary text-sm font-normal">
                  Total tickets
                </span>
                <span className="text-[#1D1F2C] text-base font-bold">200</span>
              </div>
              <SingleSelectDropdown
                options={DownloadTypes}
                onSelect={handleSelectedDownloadType}
                placeholder="All"
                placeholderColor="text-white"
                isFilterIcon
                isBackgroundColor="bg-primary text-white"
                isWidth="w-[120px] rounded-b-lg"
              />
            </div>
            <div class="flex flex-col md:flex-row items-center justify-between w-full gap-4 mt-8">
              <button
                type="button"
                className="flex items-center  gap-x-10 py-3 px-4 bg-primary hover:bg-primary focus:ring-primary focus:ring-offset-indigo-200 text-white w-full   transition ease-in duration-200 text-center text-sm font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg md:order-2"
              >
                Download 200 tickets
                <BsFiletypePdf />
              </button>
              <button
                type="button"
                onClick={() => navigate(-1)}
                className="py-3 px-4 bg-white border border-primaryGrey text-grey100 w-full md:w-1/2 transition ease-in duration-200 text-center text-sm font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg md:order-1"
              >
                Go back
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadFilter;
